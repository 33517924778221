export default {
  data() {
    return {
      moduleMap: [
        { name: "訂單紀錄", type: "order_list", value: "LiffOrderListIndex" },
        { name: "贈品紀錄", type: "gift_list", value: "LiffPointsExchange" },
        { name: "優惠券匣", type: "coupon_list", value: "LiffCouponMy" },
        { name: "領優惠券", type: "coupon_apply", value: "LiffCouponHome" },
        { name: "團購紀錄", type: "groupbuying_list", value: "LiffCoreGroupBuyingList" },
        { name: "點數專區", type: "point_list", value: "LiffPoint" },
        { name: "點數紀錄", type: "point_records", value: "LiffPointsRechargeRecords" },
        { name: "點數兌換", type: "point_exchange", value: "LiffPointsExchange" },
        { name: "兌換紀錄", type: "point_exchange_records", value: "LiffPointsExchangeRecords" },
        { name: "點數移轉", type: "point_transfer", value: "LiffPointsTransfer" },
        { name: "點數紀錄", type: "point_tab_records", value: "LiffPointsRechargeRecordsTab" },
        { name: "點數兌換", type: "point_tab_exchange", value: "LiffPointsExchangeTab" },
        { name: "兌換紀錄", type: "point_tab_exchange_records", value: "LiffPointsExchangeRecordsTab" },
        { name: "點數移轉", type: "point_tab_transfer", value: "LiffPointsTransferTab" },
        {
          name: "團購訂單",
          type: "groupbuying_order_list",
          value: "LiffCoreGroupBuyingOrderProcessingList",
        },
        { name: "編輯個人資料", type: "member_edit", value: "LiffMemberCenterEdit" },
        { name: "會員中心", type: "member_center", value: "LiffMemberCenterHome" },
        { name: "訊息中心", type: "chatroom_list", value: "LiffChatroomPortal" },

        { name: "發票模組", type: "invoice", value: "LiffEinvoice" },
        { name: "點數中心", type: "point_center", value: "LiffPointsCenter" },
        { name: "集點卡", type: "rewards_card", value: "LiffRewardsCardHome" },
      ],
    };
  },

};
