<template>
  <div class="s-layout" :style="colorObject">
    <div class="s-container">
      <div class="content">
        <Header :meta="meta" :page-title="moduleConfig.page_title" :page-image="moduleConfig.page_image"
          :header-section="moduleConfig.header_section" />
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import Header from "@/components/Page/Liff/Shared/HeaderV2";
import { mapGetters } from "vuex";
import themeColor from "@/mixins/liff/themeColor";

export default {
  mixins: [themeColor],
  components: {
    Header,
  },
  computed: {
    ...mapGetters({
      meta: "liffGeneral/meta",
      themeConfig: "liffGeneral/themeConfig",
      getModule: "liffModule/getModule",
    }),
    moduleConfig() {
      return this.getModule("liff_checkin").module_config || {};
    },
  },
  data: () => ({
    themeConfigPage: "checkin",
  }),
};
</script>

<style lang="scss" scoped>
@import "../../../assets/css/liff/main.css";
@import "../../../assets/css/liff/liff_init_theme_config.css";

.s-layout {
  color: var(--s-gray-dark);
  background-color: var(--liff-layout_bg_color);
}

.content {
  padding-bottom: 24px;
  background: var(--liff-content_bg_color);
}
</style>
<style lang="scss">
.modal-backdrop {
  opacity: 0.5 !important;
}
</style>
